.noPadding{
  padding: 0px!important
}
.message-list{
  background-image: url('./../img/Background.png');
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: contain;
  background-repeat: initial
}
.shadow{
  -webkit-box-shadow: 5px 2px 20px -3px rgba(173,173,173,1);
  -moz-box-shadow: 5px 2px 20px -3px rgba(173,173,173,1);
  box-shadow: 5px 2px 20px -3px rgba(173,173,173,1);
  z-index: 99
}

.shadow2 {
  -webkit-box-shadow: 0px 0px 35px 2px rgba(186,186,186,1);
  -moz-box-shadow: 0px 0px 35px 2px rgba(186,186,186,1);
  box-shadow: 0px 0px 35px 2px rgba(186,186,186,1);
}

.btnSend{
  margin-left: 1%;
  background: #000;
  color: #fff;
  padding: 4px 17px;
  border-radius: 20px;
}

.message-list .rce-mbox-right{
  background: #ffd586
}

.message-list .rce-mbox-right-notch{
  fill: #ffd586
}

/* #ffc268 */
/* ffd586 */
.message-list .rce-mbox-text{
  padding-bottom: 8px;
  font-size: 14.2px
}
.message-list .rce-mbox-time{
  color: rgba(0, 0, 0, 0.69);
}
.fetch{
  display: block;
  margin: auto;
  margin-top: 30px;
  color: #4a8afc!important;
}
.rce-mbox{
  /* box-shadow: -1px 0px 36px -5px rgba(158,158,158,1); */
}


[class*="MuiInput-underl"]:after{
  border-bottom: 2px solid #4a8afc!important;
}

.MuiFormLabel-root-12.MuiFormLabel-focused-13{
  color: #4a8afc!important
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.inputMsg{
  height: 12.5vh;
  display:flex;
  background-color: #e6e6e6;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 3%;
  /* margin-top:7px; */
}
.inputMsg textarea{
  width:85%;
  padding: 11px 20px;
  border-radius: 5px;
  border: 1px solid #fff;
  resize: none;
}
.inputMsg span{
  font-size: 25px;
  padding: 5px;
  align-self: center;
  cursor: pointer;
}
.rce-mlist{
  padding: 0 20px
}
.chatHeader img{
  width: 50px;
  height: 50px;
  border: none !important;
  background: #ccc;
  border-radius: 100%;
  overflow: hidden;
  font-size: 10px;
  text-align: center;
  line-height: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px
}
.chatHeader{
  display: flex;
  flex-direction: row;
  padding: 11px 25px
}
.chatHeader h6{
  font-size: 16px;
  align-self: center;
  cursor: pointer;
  margin-bottom: 0
}
.loadChat{
  display: block;
    text-align: center;
    color: #bdbcbc;
    font-size: 25px;
    margin-top: 100px;
}
.mulitSelectMaxHeight, .mulitSelectMaxHeight .kn-multi_select__wrapper___30BEc {
  height: 290px!important
}
.mulitSelectMaxHeight .ReactVirtualized__Grid{
  height: 200px!important
}
.orderAmount{
  display: flex;
  flex-direction: row;
}
.orderAmount input{
  /* width: 30%!important; */

}
.orderAmount h5{
  align-self: center;
  white-space: nowrap
}
.orderAmount i {
  font-size: 20px;
  align-self: center;
}
.orderAmount .inputContainer{
  display: flex;
  flex-direction: row;
  /* margin-bottom: 15px */
}

.orderAmount .inputContainer input {
  margin-right: 20px;
  /* width: 100%!important */
}

.orderAmount .inputContainer .iplus{
  font-size: 25px;
  color: green;
  cursor: pointer;
}
.orderAmount input{
   /* width: 100%!important */
}
.fullWidth input{
   width: 100%!important
}
.rce-mbox-text:after{
  content: none!important;
}

.displayFlexRow{
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.ownCheckbox i {
  font-size: 17px!important;
  margin-right: 5px;
  margin-left: 10px
}
.ownCheckbox span{
  align-self: center;
}
#reactive-paginator30{
  max-height: 250px!important;
  border-radius: 20px
}

@media screen and (max-width: 700px) {
  .chat-box{

    &_header{

      &_back-btn{
        font-size: 25px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;
        cursor: pointer;
      }

    }
  }
}

.chat-box{
  &_header{
    flex: 0 1 40px;
    display: flex;
    flex-direction: row;
    border-bottom: .5px solid #ECF3FF;
    cursor: pointer;
    align-items : center;
    height: 15%;
    background-color: #e6e6e6;
    &_img-wrapper{
      padding: 10px;
      display: flex;
      height: 100%;
      padding-left: 30px;
    }

    &_img{
      height: 100%;
      border-radius: 50%;
      border-color: #ECF3FF;
      border-width: 0.5px;
    }

    &_user-name{
      flex: 6;
      padding-left: 10px;
    }

    &_back-btn{
      display: none;
    }

  }

  &_messages{
    overflow-y: scroll;
    height:100%;
    max-height:100%;
    flex: 1 1 auto;
    background-image: url('./../../img/Background.png');
    background-size: contain;
  }

}

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";
// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';




#container {
	min-width: 310px;
	max-width: 800px;
	height: 400px;
	margin: 0 auto;
}

html body .app.flex-row.align-items-center {
	height: 100vh;
}

// ie11 floating footer temp fix, react only
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	#root {
		display: flex;
		flex-direction: column;
	}
}
.sidebar {
	background-color: #4dbd74
	// background-color: #3a4248
}


.sidebar .nav-link {
	display: block;
	padding: 0.75rem 1rem;
	color: #3f4248;
	text-decoration: none;
	background: transparent;


}


.sidebar .nav-link:hover {
	color: white;
	background: #3a4248;
}


.sidebar-minimized .sidebar .nav-link:hover {
	width: 250px;
	background: #3a4248 !important;
}


.sidebar .nav-link .nav-icon {
	display: inline-block;
	width: 1.09375rem;
	margin: 0 0.5rem 0 0;
	font-size: 0.875rem;
	color: #3a4248;
	text-align: center;

}


.sidebar .nav-title {
	// padding: 0.75rem 1rem;
	padding: 15px;
	font-size: 100%;
	font-weight: bold;
	color: #3f4248;
	text-transform: uppercase;


}

.sidebar .nav-link.active .nav-icon {
	color: white;
}



@media (min-width: 576px){
	.modal-dialog {
		max-width: 700px;
		margin: 1.75rem auto;
	}
}

input {
	outline: 0 !important;
	color:#3f4248
}

textarea {
	outline: 0 !important;
	color:#3f4248
}


// .app-footer {
//     -webkit-flex: 0 0 0px;
//     flex: 0 0 0px;
//     display: none
// }

.breadcrumb-item {
	color: #4dbd74
}


.click:hover {
	color: white;
	background-color: black !important
}

.click-text:hover {
	background-color: black !important;
	color: white
}



a {
	color: #4dbd74;
	text-decoration: none;
	background-color: transparent;
}


a:hover {
	color: #3a4248;
	text-decoration: none;
	background-color: transparent;
}


.gkl {
	margin:5px;
	padding:5px;
	padding-left:20px;
	background-color: white;
	width:100%;
	height: 110px;
	overflow: auto;
	text-align:justify;
	border: 1px solid #4dbd74;
	border-radius: 5px;


}
.divk div {
	display: inline-block;

}

.subdiv {
	margin-left: 10px
}


.badge-info {
	background-color: white;
	color: black
}
// .app-header .nav-item .nav-link {
//     padding-top: 0;
//     padding-bottom: 0;
//     background: 0;
//     border: 0;
//     padding: 10px;
//     border-radius:8px
// }
//
//
// .app-header .nav-item .nav-link:hover {
//     padding-top: 0;
//     padding-bottom: 0;
//     background: #4dbd74;
//     border: 0;
// }


@media (min-width: 576px){
	.goCustomer {
		max-width: 1000px;
		margin: 1.75rem auto;
	}
}


@media (min-width: 576px){
	.goFixer {
		max-width: 1000px;
		margin: 1.75rem auto;
	}
}



.triangle-down {
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 14px solid #555;
	position: absolute;
	top: 35px;
	right:305px

}


.triangle-downCh {
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 14px solid #555;
	position: absolute;
	top: 35px;
	right:357px

}

.promoCategory{
	border: 1px solid black;
	border-radius:5px
}

.inputPromoCat{
	border-bottom: 1px solid black
}


.address {
	border: 1px solid #777777
}
.table th, .table td{
	padding: 10px;
	vertical-align: inherit;
}
.table thead th{
	vertical-align: inherit;
}
.trelloCardLink{
	color: #000!important;
	background-color: #0000!important;
}
.trelloCardLink:hover{
	color: #4dbd74!important;
}
.page-link{
	color: #000;
}
.pagination{
	margin-right: 15px
}
.table th, .table td{
	padding: 5px
}
.sidebar .nav-dropdown.open .nav-link{
	color: #3f4248;
}
.ps-container .nav-item .active, .sidebar .nav-dropdown.open .nav-link:hover{
	color: #fff!important;
}
.shadow{
	box-shadow: 0px 0px 13px -2px rgba(122,122,122,1);
}
.actionsBtn{
	// margin: 0 10px 0 10px;
	// background-color: #4dbd74;
	// margin-bottom: 7px;
	// border: 2px solid #cabdbd;
	// border-radius: 20px;
	// box-shadow: 0px 0px 6px -2px rgba(122,122,122,1);
	// font-weight: bold;
	// padding: 7px 15px;
	margin: 0px 7px 7px;
	border: 0px solid #cabdbd;
	border-radius: 4px;
	box-shadow: 6px 7px 13px -12px #7a7a7a;
	// font-weight: bold;
	color: #333;
	padding: 5.5px 10px;
	background-color: #4dbd74;
	// -webkit-box-align: baseline;
	//     align-items: baseline;
	//     box-sizing: border-box;
	//     display: inline-flex;
	//     font-size: inherit;
	//     font-style: normal;
	//     font-weight: normal;
	//     max-width: 100%;
	//     text-align: center;
	//     white-space: nowrap;
	//     cursor: default;
	//     height: 2.28571em;
	//     line-height: 2.28571em;
	//     vertical-align: middle;
	//     width: auto;
	//     color: #333 !important;
	//     border-width: 0px;
	//     text-decoration: none;
	//     border-radius: 3px;
	//     padding: 0px 8px;
	//     transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
	//     outline: none !important;
	// 		margin: 7px
}
.actionsBtnPicker{
	border: 1px solid #ededed;
	padding: 8px;
}
.btnClose{
	color: rgb(80, 95, 121) !important;
	background: rgba(9, 30, 66, 0.04);
	box-shadow: 0px 3px 13px -12px #7a7a7a;
}
.btnClose:hover{
	background: #091e4226 !important;
}
.actionsBtn:hover{
	color: #4dbd74;
	background-color: #23282c;
	border-color: grey;
}

.loading .modal-content{
	border: 0px solid #0003;
}
.loading ._loading_overlay_content{
	margin-top: 25%;
	font-size: 22px;
	font-weight: bold;
}

.customerAddress{
	margin-bottom: 8px;
	width: 25%;
	text-align: center;
	border: 1px solid #ddd;
	margin: 0 30px 20px;
	padding: 12px;
	border-radius: 10px;
	cursor: pointer;
}
.activeCustomerAddress{
	margin-bottom: 8px;
	width: 25%;
	text-align: center;
	border: 1px solid #ddd;
	margin: 0 30px 20px;
	padding: 12px;
	border-radius: 10px;
	cursor: pointer;
	background-color: #4dbd74;
	color: #000;
}
.customerAddress:hover{
	background-color: #4dbd74;
	color: #000;
}
.titles{
	font-weight: bold;
}
.deleteImage{
	position:absolute;
	top:15px;
	right: 30px;
	color:#777;
	background: #fff;
	padding: 6px 8px;
	cursor: pointer;
	font-size: 25px;
	border-radius: 50%
}
.dropdown-toggle::after{
	vertical-align: 0.15em;
}
.childCatergory .dropdown-toggle::after{
	margin-left: 3.255em;
}
.cardItems{
	display: flex;
	justify-content: space-around;
	align-self: center;
}
.cardItems i{
	font-size: 18px;
	margin-top: 2px;
}
.cardItems span{
	font-size: 17px
}
.otherItem button{

}
.accordion__panel{
	cursor: pointer;
	padding: 7px 20px;
	border-bottom: 1px solid #eee;
}

.areaMenu{
	margin-left: 2%
}

.areaMenu .dropdown-menu{
	max-height: 400px;
	overflow: auto;
}
.react-datepicker__input-container input{
	padding: 5px 13px;
	border-radius: 20px;
	border: 1px solid #777;
}

.date{
	display: flex;
	// margin-top: 0.2%;
	margin: 0px 10px
}
.sidebar .nav-dropdown.open > .nav-dropdown-items i{
	width: auto;
}

.reply{
	background-color: #d2d6da;
	padding: 6px 12px;
	border-radius: 20px;
	margin-bottom: 1%;
}
.smallItem .dropdown-item{
	padding: 4px 20px
}

.noInternet {
	background: #fdfdfe;
	height: 100vh;
}

.noInternet img{
	width: 500px;
	display: block;
	margin: auto;
}



@media (min-width: 576px){
	.modal-dialog {
		max-width: 1000px;
		margin: 1.75rem auto;
	}
}
.sidebar .nav-dropdown.open .nav-link{
	font-size: 11px
}
.scrolledMenu{
	max-height: 400px;
	overflow: scroll;
}
.scrolledMenu button{
	text-align: center;
}
// .main .container-fluid{
// 	padding: 0
// }
// .card-body{
// 	padding: 5px
// }


.moreItem{
	cursor: pointer;
	padding: 18px;
	background: #f4f4f4;
	border-bottom: 1px solid #eee;
	margin-bottom: 0;
}

.estimatePrice{
	text-align: center;
	margin-top: auto;
	margin-bottom: auto;
}

.estimatePrice span{
	font-size: 30px;
	background: #4dbd74;
	padding: 5px 10px;
	border-radius: 10px;
	font-weight: 500;
}


.marker{
	position: inherit;
	text-align: center;
	font-family: sans-serif,
}
.hoveredMarker{
	position: absolute;
	background: #fff;
	padding: 10px 20px;
	top: -90px;
	left: -87px;
	border-radius: 20px;
	box-shadow: 0px 0px 13px -2px rgba(122,122,122,1);
}
.hoveredMarker h6{
	font-size: 12px;
	white-space: nowrap;
	margin-bottom: 0
}
.date .react-datepicker-popper{
	z-index: 99999999!important;
	top:-4%!important
}
.clearDatePicker{
	margin-left: -2.5%;
	z-index: 9;
	margin-top: 1%;
	color: #757575;
	margin-right: 1.6%;
	cursor: pointer;
}


// enhancement table
.table-bordered th, .table-bordered td{
	border:0px;
	border-top: 1px solid #c8ced3;
}
.table-striped tbody tr:nth-of-type(odd){
	background: #00000008
}

.table thead th {
	vertical-align: inherit;
	background: #00000030;
	color: #3a4248;
	// border-color: #3a4248;
	// box-shadow: 0px 0px 6px -2px rgb(35, 35, 35)
}
.modal-footer{
	border-top: 0px
}
.modal-header{
	border-bottom: 0px;
	padding: 1.5rem 2rem;
}
button:focus{
	outline: 0px auto -webkit-focus-ring-color
}
.react-datepicker__input-container input{
	border-radius: 4px!important;
	border: 1px solid #fff!important;
}
.table th, .table td {
	padding: 10px 15px;
}

.iconAgent{
	border-radius: 50%;
	box-shadow: 0px 0px 5px -2px #7a7a7a;
	background-color: #4dbd74;
	padding: 7px 9px;
	cursor: pointer;
}

.table-striped tbody tr:hover{
	background: #0000000d!important
}
.actionsBtnEntries{
	// margin: 0px!important
	margin-right: 10px !important
}
.jss4.jss2 {
	color: #4dbd74!important;
}


// fix new design issues

.table th, .table td{
	padding: 10px 5px
}
.btn-secondary:focus, .btn-secondary.focus{
	box-shadow: none;
}

.modal-backdrop{
	background-color: #3e3e3e9e!important
}
.noWrap{
	white-space: nowrap;
	overflow: hidden;
}
.someSpace{
	margin: 0px 3px;
	padding: 2px 5px;
}
.orderBadges span{
	background: #3a4248;
	border-radius: 4px;
	display: inline-block;
	color: #fff;
	font-weight: bold;
	font-size: 12px;
	cursor: pointer;
}
.customShadow{
	// box-shadow: 0px 0px 28px -15px rgba(150,150,150,1);
	box-shadow: 0px 0px 5px -3px #000
}
.approvedCondition .kn-item_label__label___2fJ5p{
	direction: rtl;
}
.react-confirm-alert-overlay{
	z-index: 2000!important;
}


.quotation p {
	margin-bottom: 0px;
}
.toggleCheckbox{
	display: flex;
	flex-direction: row;
	align-self: center;
	cursor: pointer;
}
.toggleCheckbox i{
	margin-right: 5px;
	align-self: center;
}
.truncate{
	max-width: 140px;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
	padding: 6px 10px;
	font-size: 12px
}

.removePaddingFromResponsive{
	padding: 0px !important;
}

.productItem,.keywordItem{
	border: 1px solid #ededed;
	// padding: 15px;
	border-radius: 10px;
	margin: 10px
}
.productItem,.keywordItem h6{
	margin-top: 10px;
	font-size: 16px
}
.productItem,.keywordItem a{
	text-decoration: underline;
}

.orderActions i{
	margin-left: 10px
}
#promotedKey__imageUploader{
  visibility: hidden;
}
.image__clear{
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}
.uploadImg h6{
	color: #777
}
.uploadImg i{
	color: #777;
	font-size: 17px;
	margin-left: 0px;
	margin-right: 6px
}
.uploadImg p{
	cursor: pointer;
}
#image-uploader,#image-uploader2{
  cursor: pointer !important;
  position: relative;
  visibility: hidden;
}
#image-eraser{
  position: relative;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

}
#image-eraser:hover #image-eraser-icon {
  visibility:visible;
}

#image-eraser-icon{
  visibility: hidden;
  position: absolute;
  cursor: pointer;
  top: -5;
  left: 100;
  z-index: 999999;
}
#promotedKey__imageUploader{
  visibility: hidden;
}
.image__clear{
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}
.navbar-nav .open > .nav-link, .navbar-nav .open > .nav-link:hover, .navbar-nav .open > .nav-link:focus, .navbar-nav .active > .nav-link, .navbar-nav .active > .nav-link:hover, .navbar-nav .active > .nav-link:focus, .navbar-nav .nav-link.open, .navbar-nav .nav-link.open:hover, .navbar-nav .nav-link.open:focus, .navbar-nav .nav-link.active, .navbar-nav .nav-link.active:hover, .navbar-nav .nav-link.active:focus{
	color:#4dbd74
}

.editor-editor-class{
	overflow: scroll;
	max-height: 200px;
}
.multiFilter{
	border-radius: 50px;
margin-right: 5px;
font-size: 12px;
padding: 5px 15px;
background: #ededed;
border: 0;
box-shadow: 0px 0px 0px 1px #777;
box-shadow: 0px 0px 4px -1px rgba(145,139,145,1);
margin-top: 5px
}
.display-linebreak {
	white-space: pre-line;
}
.chat-list-item{
  z-index: unset !important;
}
.message-snippit-btn{
  background-color: transparent !important;
  border: 0px solid transparent !important;
  margin: 0 !important;
}

.message-attachment-container,.message-attachment-container:hover,.message-attachment-container:active {
  background-color: transparent !important;
  cursor: unset !important;
  border: 0 !important;
}

.message-attachment-btn,.message-attachment-btn:hover,.message-attachment-btn:active{
  border:  0 !important;
  cursor: unset !important;
  background-color: transparent !important;
}
.estimateCard{
	text-align: center;
    background: #fbfbfb;
    // border: 1px solid #ffa800;
		box-shadow:         0px 0px 4px 0px rgba(50, 50, 50, 0.5);
		margin: 10px;
    border-radius: 20px;
    padding: 15px;
		align-self: center;
}
.estimateCard .cardRow{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	border-bottom: 1px solid #4dbd74;
	padding-bottom: 5px;
	font-weight: bold;
}
.toggleCheckbox .btn{
	background-color: #4dbd74;
	padding: 4px 18px;
	border-radius: 8
}
.notification-circle{
	position: absolute;
    right: -16px;
    top: -11px;
    color: #ffffff;
    font-size: 10px;
    background-color: red;
    border-radius: 50%;
    padding: 3px;
    z-index: 99999999;
}
#reactive-paginator10{
	overflow: scroll;
	max-height: 250px
}

.dropdown-menu{
	border-radius: 20px;
  box-shadow:0px 0px 8px 0px rgba(153,153,153,0.77);
}
.dropdown-menu button{
	text-align: center;
border-bottom: .3px solid #ededed;
font-size: 13px
}
.list-group-item{
  padding: 7px 7px 0px;
  border:0px solid rgba(0, 0, 0, 0.125);
	margin-bottom: 1px
}
.chat_item_avatar{
  width: 12%;
}
.chat_item_metadata_header_username{
	font-size: 15px;
	font-weight: bold;
}

.chat_item_metadata {
    float: left;
    width: 85%;
    padding: 0 10px 7px;
    border-bottom: 1px solid #ededed;
		margin-left: 10px
}
.chat_item_metadata_message_sender {
    float: left;
    font-weight: 400;
    width: 25%;
    color: #555;
		font-size: 12px;
		margin-top: 2px;
}
.chat_item_metadata_message_content{
	    width: 65%;
			color: #999;
    direction: rtl;
}
.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #f9b415;
    border-color: #f9b415;
}
.chat-panel_header{
	padding: 0px 10px
}
.customer-chat-header,  .fixer-chat-header{
	flex: 0 0 12%;
	align-self: center;
}
.input-group-text, .form-control{
	border-radius: 25px
}
.chat-box_header{
	height: 10%;
	background-color:#f6e7b5
}
.chat-panel_message-input{
	background-color:#f6e7b5
}
.chat-panel_message-input_text-area{
	height: 40px;
padding: 8px 20px;
font-size: 15px;
border-width: 0px;
}
.chat_item{
	cursor: pointer;
}
.active .chat_item_metadata{
	border-bottom: 0px solid #ededed;
}

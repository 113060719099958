.message-caption{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.message-image-caption,.message-image-caption:focus {
  border: 0 !important;
  border-radius: 0 !important;
  border-bottom: 3px solid rgb(255, 153, 0) !important;
  box-shadow: unset !important;
}

.message-image-modal{
  display: flex;
  flex-direction: column;
  padding: 0 20%;
  justify-content: center;
  align-items: center;
}


.message-input-image{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &-preview{
    width: 70%;
    margin: 10px;
  }
  &-clear{
    cursor: pointer;
    margin-top: -10px;
  }
}

.rce-container-mlist {
    position: relative;
    display: flex;
}

.rce-mlist {
    display: block;
    overflow: auto;
    position: relative;
    flex: 1;
}

.rce-mlist-down-button {
    position: absolute;
    right: 10px;
    bottom: 15px;
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.05), 0 2px 5px 0 rgba(0,0,0,0.1);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    cursor: pointer;
    transition: 200ms;
}

.rce-mlist-down-button:hover {
    opacity: 0.7;
}

.rce-mlist-down-button--badge {
    position: absolute;
    right: -5px;
    top: -5px;
    background: red;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    font-size: 12px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 700;
}

@media screen and (max-width: 700px) {
    .chat-panel_chats-list{
      width: 100% !important;
    }
    .chat-panel_box{
      display: none !important;
    }

    .chat-panel_box_show{
      width: 100% !important;
      display: flex !important;
    }

    .chat-panel_chats-list_hide{
      display: none !important;
    }

    .chat-panel_message-input_text-area{
      font-size: 12px !important;
    }
}

.chat-panel{
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: white;

  &_online-status{
    background-color:#4dbd74;
    font-size:1.6rem;
    color:white;
    height:50px;
    text-align:center;
    transition: all 0.9s ease-in-out;
  }

  &_offline-status{
    background-color:#F86C81;
    font-size:1.6rem;
    color:white;
    height:50px;
    text-align:center;
    transition: all 0.9s ease-in-out;

    span{
      padding: 10px;
    }
  }

  &_header{
    background-color: white;
    padding: 10px;
    display: flex;
  }

  &_message-input{
    display: flex;
    flex: 0 1 40px;
    background-color: #e6e6e6;
    transition: all 100000ms linear;
    padding: 5px 10px;

    span{
      cursor: pointer;
    }

    &_emoji{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      padding: 10px;
    }

    &_text-area{
      flex: 1;
      padding: 11px 20px;
      border: 1px solid #fff;
      resize: none;
      font-size: 25px;
      background-color:#ECF3FF;
      border-radius:100px;
      font-size:16px;
      height:50px;
      margin:5px;
    }

    &_attachment{
      display:flex;
      align-items:center;
      justify-content:center;
    }

    &_send-block{
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  &_chats-list{
    display: flex;
    flex-flow: column;
    height: 100%;
    float: left;
    width: 35%;
    border: 1px solid #ECF3FF ;
  }

  &_box{
    display: flex;
    flex-flow: column;
    height: 100%;
    float: left;
    width: 65%;
  }

}

@media screen and (max-width: 700px) {
  .chat_audio-recorder_counter{
    font-size: 14px !important;
    width: 25% !important;
  }
}

.chat_audio-recorder{
  &_cancel-btn{
    display:flex;
    align-items:center;
    justify-content:center;
    margin:0px 10px;
  }

  &_counter{
    display:flex;
    justify-content:center;
    align-items:center;
    font-weight:bold;
    font-size:16px;
    height:50px;
    margin:5px;
    width:10%;
  }

  &_send-btn{
    display:flex;
    align-items:center;
    justify-content:center;
    margin:0px 10px;
  }

  &_mic{
    display:flex;
    align-items:center;
    justify-content:center;
    margin:0px 10px;
  }

}

$grey :rgb(136, 149, 154) ;

.chat_item {
  width: 100%;
  position: relative;

  &_avatar{
    width: 20%;
    float: left;

    &_icon{
      width: 100%;
      border-radius: 50%;
      cursor: pointer;
    }

  }

  &_metadata{
    float: left;
    width: 80%;
    padding: 0 10px;

    &_header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &_username{
        font-weight: 900;
      }

      &_time{
        font-size: 1.2em;
        color: rgb(136, 149, 154) ;
        color: $grey ;
      }

    }

    &_message{
      flex-direction: row;

      &_sender{
        float: left;
        font-weight:600;
        width: 20%;
      }

      &_content{
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 2px;
        width: 70%;
        color: rgb(88, 100, 105);
      }

      &_seen-count{
        float: left;
        padding: 3px;
        width: 10%;
      }
    }

  }

}

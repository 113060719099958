// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './assets/style/style.scss';

.noInternetModal .modal-content{
  background: transparent!important
}
.noInternetModal h2{
  text-align: center;
  color: #fff;
  font-size: 33px;
  font-weight: bold;
}
.noInternetModal img{
  height: 200px;
  display: block;
  margin: auto;
}
.internet{
  display: block;
  margin: auto;
}


.assignOrdersToAgentContainer{
  margin: 15px
}


// .iconAgent{
// border-radius:50%;
// width: 40px !important;
// height: 40px !important;
// box-shadow: 0px 0px 5px -2px rgba(122,122,122,1);
// background-color:  #4dbd74;
// text-align:center;
// // font-size: 12px;
// padding: 8px 0px 8px 0px ;
// letter-spacing: 0px;
// font-weight: lighter;
// }


.iconAgent:hover{
  background-color:  #4dbd74;

}


.containerIcon{
  display: inline-block;
}


.hoverItemName{
  box-shadow: 0px 0px 6px -2px rgba(122,122,122,1);
  border-radius: 5px;
}

.addNote{
  resize: none;
  height: 100px !important;
}

.checkAddNote{
  width: 0
}


.textareaconditions{
  resize: none;
}


.listServiceGroups{
  list-style-type: dash;

}


.tableConditionsDetails{
  width: 80%;
  margin: auto;
}


.historyCareteria{
  padding: 0 !important
}







// table entries


.descriptionRow{
	width: 30%;
  // padding-left: 10px !important;
	// padding-right: 10px !important;
}


.debitCreditHead{
	width: 50%;
	vertical-align: inherit !important;
	padding-top: 20px !important
}


.colspanParties{
	padding-bottom: 20px !important;
}


.childAccountName{
	padding-bottom: 5px !important;
	// border-top: 0px solid #000 !important;
	border-top: 0px solid #eee !important;
	border-right: 0px solid #000 !important;
	border-bottom:0px !important
}


.idAccount{
  border: 2px solid #000 !important
}

.dateAccount{
	border: 2px solid #000 !important

}


.parentAccountHead{
		border: 0
}


.descriptionAccount{
	border: 2px solid #000 !important
}


.debitAccountHead{
	border: 2px solid #000 !important
}


.childAccountHead{
	border-left: 2px solid #000 !important;
	border-bottom: 0px solid #000 !important
}


.colspanParties{
	border-bottom: 2px solid #000 !important;
  border-top: 0px solid #000 !important;
	border-right: 0px solid #000 !important;
	border-left: 0px solid #000 !important;
}

.childAccountHeadTwo{
	border-left: 0px solid #000 !important;
	border-bottom: 0px solid #000 !important;

}

.typeDebit {
	border-left: 0px solid #000 !important;
	border-bottom: 2px solid #000 !important;
	border-right: 0px solid #000 !important;
	border-top: 0px solid #000 !important;
	padding-top: 20px !important

}

.creditHead{
	border-left: 2px solid #000 !important;
	border-bottom: 0px solid #000 !important;
  padding-top: 20px !important
}


.debitCreditHead{
	border-left: 0px solid #000 !important;
	border-bottom: 0px solid #000 !important;

}

.debitBody{
	border-top: 0px solid #000 !important;
	border-left: 0px solid #000 !important;
  width: 50% !important

}

.creditBody{
	border-top: 0px solid #000 !important;
	border-right: 0px solid #000 !important;
  width: 50% !important

}

.parentAccountHeadTow{
	border-top: 2px solid #000 !important;
	border-bottom: 2px solid #000 !important;
	border-left:1px solid #000 !important;
	border-right: 1px solid #000 !important
}


.allEntries th, .allEntries td {
	padding:0;
	vertical-align: inherit;
}

.childDebit {
	padding: 0;
	vertical-align: top;
	width: 10% !important;
}

.allEntries thead th{
	vertical-align: inherit;

}





.entriesTable thead tr {

}

.entriesTable thead tr th {
    text-align: center!important;
    // border: 1px solid #000!important;
}



.entriesTable thead tr .headTableEntry {
  width: 70%;
}

.entriesTable tbody tr td {
  text-align: center!important;
  border-top-width:0px
}

.entryPartiesTable thead tr {
  background-color: #4dbd74

}


.entryPartiesTable thead tr th {
  text-align: center !important;
  border: 1px solid #000 !important;
}

.entryPartiesTable tbody tr td {
  text-align: center!important;

}

.entryPartiesTable {
  width: 90% !important;
  margin-left: auto;
  margin-right: auto;
}


@media (min-width: 576px) {
	.entryParties {
		max-width:700px;
		margin: 1.75rem auto;
	}
}


.datePicker{
	display: flex;
	margin-left: 3%;
	margin-top: 0.2%;
}


.react-datepicker__input-container input{
	padding: 5px 13px;
	border-radius: 20px;
	border: 1px solid #777;
  outline: 0 !important;
  box-shadow: 0px 2px 14px -4px rgba(163,161,163,1);

}

.inputAccountId{
  border: 1px solid #777;
  outline: 0 !important;
  border-radius: 20px;
  box-shadow: 0px 2px 14px -4px rgba(163,161,163,1);


}

.arrangement{
  border: 1px solid #777;
  outline: 0 !important;
  box-shadow: 3px 3px grey;
  border-radius: 20px;
  margin-top: 8px !important
}

.inputAccountId::placeholder{
  color: #777777;
}


.table {
    width: 100%;
    margin-bottom: 0 !important;
    color: #23282c;
    height: 100% !important
}



.childAccount {
  width: 50% !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  // border-top: 0px !important;
  border-left: 0px solid !important;
  border-bottom: 0px solid !important;
  // border-right: 1px solid !important;
  border-top: 0px solid #000 !important;
  // border-right: 1px solid #000 !important
  border-right: 0px solid !important;

}

.childAccountHead{
  width: 50% !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;

}

.accountTable {
  // width: 40% !important
  display: block;
  width: 100%;
  border: 0px solid !important;
}



.tableAccountPreview th ,.tableAccountPreview td {
  padding: 0.7rem !important;

}



.allEntries tbody tr:nth-of-type(odd) {
    background-color:white !important;
}


.allEntries tbody tr:nth-of-type(even) {
    background-color:white !important;
}


.card-body {
    flex: 1 1 auto;
    padding: 0.7rem;
}

@media (min-width: 768px){
  .col-md-5 {
      flex: 0 0 41.66667%;
      max-width: 37%;
  }
}



@media (min-width: 768px){
  .col-md-3 {
      flex: 0 0 25%;
      // max-width: 15%;
  }
}


.partiesPart{
  border-bottom: 2px solid ;
  padding-top: 10px;
  padding-bottom: 10px
}


.accountNameHeader{
  border-left: 0;
  padding-bottom: 10px;
  padding-top: 10px
}

.creditHeader{
  border-left: 2px solid #c8ced3;
  padding: 10px 0 10px 0;
  width: 50%
}

.accountIdContainer{
  border-right: 2px solid #c8ced3;
  padding-bottom: 0;
  padding-top: 0;
  width: 50%;
  height:60px !important

}


.accountNameContainer{
  padding-bottom: 10px;
  padding-top: 10px
}

.containerAccount{
  border-bottom: 1px solid;
  // padding-top: 10px;
  // padding-bottom: 10px;
  // border-top: 1px solid;
}


.accountId{
  border-right:1px solid

}

.accountName{
  // border-right:1px solid

}

.accountDebit{
  margin-top: 17px;
}

.containerAccountDebitCredit{
  border-bottom: 1px solid;
}


.selectDesc{
  margin: 0 10px 0 10px;
	background-color: white;
	margin-bottom: 7px;
  border: 1px solid #777;
	border-radius: 20px;
  box-shadow: 0px 2px 14px -4px rgba(163,161,163,1);
	font-weight: bold;
	padding: 7px 15px;
}

.selectDesc:hover{
  background-color: white;

}

.selectDesc:focus{
  background-color: white;

}


.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #23282c;
    background-color: white;
    border-color: white;
}


.accountNameBody{
  border-left: 1px solid #c8ced3
}


.accountsBody{
  border-bottom: 1px solid #c8ced3
}


.accountContain{
  border-bottom: 1px solid #c8ced3
}

.DebitContain {
  border-bottom: 1px solid #c8ced3

}

// .accountIdBody{
//   border-right: 1px solid
// }

.accountField{
  border-bottom: 1px solid #c8ced3
}

.accountNameItem{
  border-left: 1px solid #c8ced3;
  padding-bottom: 10px;
  padding-top: 10px;
}

.accountIdItem{
  border-left: 1px solid #c8ced3;
  padding-bottom: 10px;
  padding-top: 10px;
}


.rowAccount{
  border-bottom: 1px solid #c8ced3;
  margin-left: 0;
  margin-right: 0
}


.test{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 1px solid #c8ced3;
width: 100%;
}

.contentAccount{
  border-bottom: 1px solid #c8ced3
}


.accountNameBody{
  // border-left: 2px solid;
  // border-right: 2px solid;
  padding-bottom: 10px;
  padding-top: 10px
}

.accountNameBody {
    border-left: 2px solid;
}


.accountAll{
  border-right: 1px solid;
  border-left: 1px solid
}
.accountIdHeader{
  border-right: 0
}
.line{
  border:1px solid #c8ced3,
}

// hr {
//     margin-top: 1rem;
//     margin-bottom: 1rem;
//     border: 0;
//     border-top: 0px solid rgba(0, 0, 0, 0.1);
// }

.accountAllOne{
  border-right: 1px solid;
  border-left: 0

}


// .tableContent{
//   width: 965px !important;
//   position: relative !important;
// }

//
// .table-responsive {
//     display: block;
//     width: 965px;
//     overflow-x: auto;
// }


.parentheadAll{
  border-top: 2px solid !important;
}
.headerParent{
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
  border-right: 1px solid !important;


}


.theadParent{
  border-right: 1px solid !important
}


.ParentAccount{
  border-bottom: 1px solid #a2a2a2 !important
}

.theadTable{
  // border: 2px solid !important
}


.theadId{
    // border-bottom: 2px solid !important;
    // border-right: 2px solid !important
}

.theadDate{
    // border-bottom: 2px solid !important;
    // border-right: 2px solid !important
}
.theadDescription{
    // border-bottom: 2px solid !important;
    border-right: 1px solid #a2a2a2 !important
}
.theadParent{
    // border-bottom: 2px solid !important;
    border-right: 1px solid #a2a2a2 !important
}

.theadType{
  // border-bottom: 2px solid !important;
  // border-right: 2px solid !important

}


.theadCredit{
  border-left: 1px solid #a2a2a2 !important
}
.childAccountName{
  // border-right: 1px solid #a2a2a2 !important
}


.theadTypeChild{
  border-bottom: 1px solid #a2a2a2 !important
}



.tbodyParent{
  border-top:1px solid #c8ced3 !important;
  border-right:1px solid #c8ced3 !important;
  border-left:1px solid #c8ced3 !important;

}

.tbodyParent .childAccount{
  border-right:1px solid #c8ced3 !important;
  // border-top:1px solid #c8ced3 !important;
}



.childDebit .table-bordered td{
  border-top-width:  0px !important
  // border-top:1px solid #c8ced3 !important;
  // border-right:1px solid #c8ced3 !important;
}

// .table-bordered td

.childDebit .debitBody{
  border-right:1px solid #c8ced3 !important;
  border-top:1px solid #c8ced3 !important;
}
.childDebit .creditBody{
  border-top:1px solid #c8ced3 !important;
}

.borderTopClass{
  border-top:1px solid #c8ced3 !important;
}

.table-responsive{
  padding: 15px !important
}
.scrolledMenu{

	position: absolute;
top: 100%;
left: 0;
z-index: 1000;
display: none;
float: left;
min-width: 10rem;
padding: 0;
margin: .125rem 0 0;
font-size: .875rem;
color: #23282c;
text-align: left;
list-style: none;
background-color: #fff;
background-clip: padding-box;
 // border: 0px solid #c8ced3!important;
// border-radius: 5px!important;
background: #fbfbfb!important;
box-shadow: 0px 1px 11px -2px rgba(128,128,128,1);

}
.theadTable .table thead th{
  background: #d5d5d5!important
}




// .table-responsive {
    // display: block;
    // width: 100%;
    // overflow-x: auto;
    // -webkit-overflow-scrolling: touch;
//     padding: 15px !important;
// }







// .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
//     position: inherit;
//     width: 100%;
//    padding-right: 0;
//    padding-left: 0;
// }
//
// .columnAccount{
//   border-right: 1px solid #c8ced3
// }
//
//
// .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
//     position:inherit;
//     width: 100%;
//     padding-right: 0;
//     padding-left: 0;
// }

.actionsButton{
	margin: 0 10px 0 10px;
	background-color: white;
	margin-bottom: 7px;
	border: 2px solid #cabdbd;
	border-radius: 20px;
	box-shadow: 0px 0px 6px -2px rgba(122,122,122,1);
	font-weight: bold;
	padding: 7px 15px;
}


.tabButton{
  padding-right: 0px;
  padding-left: 0px
}

.rowTabReservation{
  margin-right: 5px !important
}


.actionBtnReservation:hover{
  background-color: #3a4248 !important;

}

.actionBtnReservation{
  border-radius: 0px;
  padding:10px;
}


.showImage{
  padding: 0px !important;
}


.btnQutaions{
  padding: 3px 5px;
}

.entriesTable tbody tr td.balanceDebitAndCredit {
    text-align: center !important;
    border-top-width: 0px;
    border-left: 1px solid #c8ced3;
    // position: relative !important;
    border-top: 1px solid #c8ced3
}

.table thead th.theadBalance {
    vertical-align: bottom;
    border-bottom: 2px solid #c8ced3;
    border-left: 1px solid #a2a2a2
}


td.creditColumnBalance{
  border-left: 1px solid #c8ced3;
  width: 50% !important;
}

.categoryList{
  list-style-type: none;
  padding: 0
}

.categoryList li {
  display: inline-block;
}
.css-1t51i91 {
  position: initial !important;
}
.actionsBtnWithoutHover:hover{
   color:#23282c;
}
.actionsBtnWithoutHover{
  margin: 0;
  border: 1px solid #cabdbd;
	border-radius: 5px;
	box-shadow: 0px 0px 0px 0px rgba(122,122,122,1);
}


.fileContainer{
  box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0.05);
}

.fileContainer .chooseFileButton{
  color:#333333
}

.detailsPosts{
  background-color: transparent; border: 1px solid rgb(26, 26, 26); color: rgb(26, 26, 26); font-size: 12px; font-weight: bold; cursor: pointer;
}

.css-1t51i91 {
  position: initial !important;
}


.css-5mzto9 {
  position: initial !important;
}


.arrow-up {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;

  border-bottom: 15px solid rgb(254, 181, 0);
  margin-left: auto !important;
  margin-right: auto !important;
}

.notifications{
  // border:1px solid rgb(254, 181,0);
  overflow-y: scroll;
  box-shadow: 0px 1px 11px -2px grey;
  border-radius: 25px;
  padding:  0px;
  // max-height: 300px;
  // overflow: scroll;
}
.notification{
  border-bottom: 1px solid #e7e7e7;
  padding: 10px;
}

.dateH6{
   margin-bottom: 0 !important;
}
.dropMenu{
  max-height: 253px;
  overflow-y: scroll;
}
